import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import "lib-flexible/flexible.js";
import { Icon } from "vant";
import wx from "weixin-js-sdk";
const app = createApp(App);
app.config.globalProperties.$wx = wx;
app.use(store).use(router).use(vant).use(Icon).mount("#app");
