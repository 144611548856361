import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/havedshipped",
    name: "HavedShipped",
    component: () => import("../views/HavedShipped.vue"),
  },
  {
    path: "/sendGoods",
    name: "SendGoods",
    component: () => import("../views/SendGoods.vue"),
  },
  {
    path: "/productdetails",
    name: "ProductDetails",
    component: () => import("../views/ProductDetails.vue"),
  },
  {
    path: "/shippingdetails",
    name: "ShippingDetails",
    component: () => import("../views/ShippingDetails.vue"),
  },
  {
    path: "/home",
    name: "homePage",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/search",
    name: "searchPage",
    component: () => import("../views/search/index.vue"),
  },
  {
    path: "/details",
    name: "details",
    component: () => import("../views/details/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
